.IncomingVideoContainer {
  height: 100%;
  width: 100%;
  border: 1px solid rgba(235, 235, 245, 0.4);
  border-radius: 8px;
  position: relative;
}

.incomingVideo {
  border-radius: 12px;
  width: 100%;
  height: 100%;
}
