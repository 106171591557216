.HeaderTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-family: MetropolisSemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    align-items: center;
  }
  .HeaderSubTitle {
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #7A7A7A;
  }
  .MenuTopDiv {
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    display: flex;
    padding: 6px;
    gap: 6px;
    align-self: stretch;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    background: var(--Dark-Mode-Primary-Light, #FFF);
  }
  .MenuItem {
    display: flex;
    height: 32px;
    padding: 6px 12px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }
  .SearchBarSection {
    margin-top: 24px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
  }
  .SearchBarDiv {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--neutral-gray-100-d-5-d-9-dd, #DADEE1);
    background: #FFF;
  }
  .SearchBarInput {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--neutral-gray-100-d-5-d-9-dd, #DADEE1);
    background: #FFF;
    color: var(--neutral-gray-400808-d-9-c, #808D9C);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    flex: 1 0 0;
    outline: none;
    border: none;
  }
  .CheckBoxListSection {
    margin-top: 24px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    height: 184px;
    max-height: 184px;
    overflow-y: scroll;
  }
  .CheckBoxListHeading {
    display: flex;
    padding: 6px 0px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid #DADEE1;
    color: #808D9C;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }
  