.header {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header img {
  height: 48px;
  width: 128.81px;
}