.callRoom {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2c2c2e;
  width: calc(100% - 48px);
  height: calc(100vh - 48px);
  padding: 24px;
  gap: 24px;
}
.headerDivWrapper{
  width: 100%;
}
.headerDiv{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.roomName {
  font-family: MetropolisSemiBold;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.privacySecuredText{
  font-family:  MetropolisRegular;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  align-self: center;
}
.videoDiv {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.videoDivVisible {
  opacity: 1;
  transform: scale(1);
}

.videoContainer {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.callDetails {
  color: #ffffff;
  display: flex;
  height: 28px;
  gap:8px;
  align-items: center;
  width: 240px;
}
.imgContainer {
  margin-right: 167px;
}
.timeText{
 font-family: 'MetropolisMedium', sans-serif;
 font-weight: 500;
 font-size: 16px;
 line-height: 24px;
}
.videoGridContainerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.waitingText{
  margin: 0;
  padding: 0;
  color: #EBEBF599;
  font-family: MetropolisSemiBold;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  align-self: center;
}

.videoGridContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  place-content: center;
  position: relative;
  background-color: #555558;
  border-color: #555558;
  border-radius: 8px;
  .videoDiv {
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
    }
  }  
}
.cameraControlsDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 8px;
  border-radius: 8px;
  padding: 4px;
  background-color: #4F4F4F;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MetropolisMedium', sans-serif;
  font-weight: 600;
  z-index: 100;
}
.nameBox {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  height: 40px;
  padding: 4px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6), 0 0 2px rgba(0, 0, 0, .3);
  font-size: 14px;;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MetropolisMedium', sans-serif;
  font-weight: 600;
}
.privacySecureContainer {
  border-radius: 16px;
  height: 24px;
  background-color: #BDE7CE33;
  color: #5FC88E;
  display: flex;
  padding-top:4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.localVideoStyle{
  position: absolute;
  width: 17%;
  right: 1%;
  bottom: 2%;
  border-radius: 8px;
  border: 6px solid #2C2C2E;
  background-color: #2C2C2E;
  z-index: 1000;
  display: flex;
  justify-content: center;
}
.presentingDiv{
  margin: 8px;
  height: 40px;
  border: 1px solid #F94D59;
  border-radius: 8px;
  padding: 4px;
  background-color: #F94D59;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MetropolisMedium', sans-serif;
  font-weight: 600;
  z-index: 1000;
}
.muteIndicatorDiv {
  margin-left: 12px;
  width: 28px; 
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .4);

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.button {
  font-family: MetropolisSemiBold;
  font-size: 16px;
  column-gap: 13px;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.pinnedVideoActiveDiv{
  flex: 2;
  display: flex;
  padding: 2rem;
  justify-content: center;
  position:relative;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderImage {
  animation: spin 1s linear infinite;
}
