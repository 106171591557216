.tooltipInfoTextContainer {
    position: absolute;
    font-family: MetropolisMedium;
    width: max-content;
    font-size: 14px;
    line-height: 150%;
    max-width: 170px;
    background-color: #555558;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    z-index: 10000;
    text-align: center;
    text-transform: initial;

    display: none;
    opacity: 0;

    transition: opacity 0.5s ease-in-out;
    white-space: pre-line;
}

.toolTipArrowLeft::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #555558;
}

.toolTipArrowRight::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #555558;
}

.toolTipArrowBottom::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #555558;
}

.toolTipArrowTop::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #555558;
}