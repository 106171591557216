
.OverlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.ContainerStyle {
    width: 720px;
    height: 500px;
    background: #fff;
    padding: 32px 24px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
.CheckBoxStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    padding-top: 0.2rem;
 }
  
.BackButtonDiv {
    display: flex;
    position: absolute;
    left: 16px;
    top: 16px;
    cursor: pointer;
    color: var(--primary-purple-p-4008549-ff, var(--Primary-P-400, #8549FF));
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
 
  
  .Footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }
  .FooterLeftSection {
    display: flex;
    align-items: center;
    gap: 24px;
    color: var(--primary-purple-p-4008549-ff, var(--Primary-P-400, #8549FF));
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .buttonDisabled {
    font-family: MetropolisSemiBold;
    font-size: 16px;
    column-gap: 13px;
    border-radius: 8px;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    width: 160px;
    height: 40px;
    padding: 12px;
    background-color: #8549FF;  
  }

 .parent {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    gap: 24px;
  }
  .imgAnimation{
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
}
  
  
  