.drownDownContainer {
    font-family: MetropolisMedium;
    position: relative;
    width: 200px;
}

.titleContainer {
    padding: 16px 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: inherit;
    border: 1px solid #DADEE1;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
}

.titleContainer:hover {
    -webkit-box-shadow: 0px 0px 0px 3px rgba(244, 244, 245, 1);
    -moz-box-shadow: 0px 0px 0px 3px rgba(244, 244, 245, 1);
    box-shadow: 0px 0px 0px 3px rgba(244, 244, 245, 1);
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #808D9C;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.titleSelected {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #141414;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.iconsDiv {
    display: flex;
    flex-direction: column;
    gap: 3.43px;
}

.dropDownActive {
    border: 1px solid var(--p-400);
    -webkit-box-shadow: 0px 0px 0px 3px var(--p-50);
    -moz-box-shadow: 0px 0px 0px 3px var(--p-50);
    box-shadow: 0px 0px 0px 3px var(--p-50);
}

.dropDownActive .title {
    color: #141414;
}

.dropDownValuesContainer {
    display: flex;
    position: absolute;
    z-index: 4000;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    border: 1px solid #DADEE1;
    border-radius: 12px;
    box-shadow: 0px 16px 16px -4px #10182814;
    background-color: var(--n-00);
    max-height: 220px;
    overflow: scroll;
}

.dropDownItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: #FFFFFF;
}

.dropDownItemTitle {
    margin: 0;
    max-width: calc(100% - 5px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dropDownItem:hover {
    background-color: #F7F7F8;
}

.dropDownItemSelected {
    background-color: #F9F5FF !important;
    color: var(--p-400) !important;
}