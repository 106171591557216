.namePlate {
  top: 8px;
  left: 8px;
  position: absolute;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-style: bold;
  font-weight: 600;
  line-height: 20px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6), 0 0 2px rgba(0, 0, 0, .3);
}
