.videoStream {
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.popUpBox{
  position: absolute;
  width: 300px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6000;
}
.loaderImage {
  animation: spin 1s linear infinite;
}