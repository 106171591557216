.button {
  font-family: MetropolisSemiBold;
  font-size: 16px;
  column-gap: 13px;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.button:hover{
  opacity: 0.8;
}

.buttonHoverColor {
  font-family: MetropolisSemiBold;
  font-size: 16px;
  column-gap: 13px;
  border-radius: 8px;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: #FFFFFF;
}

.buttonHoverColor:hover {
  background-color: #DADEE1;
}

.standardBtn {
  background-color: #fff;
}

.GrayBorderlessButton {
  background-color: transparent;
  color: #808d9c;
}

.GrayBorderlessButton:hover {
  color: #a9b2bc;
}

.GrayButton:hover {
  background-color: #f0f0f0;
}

.btnDisabled {
  background-color: #e5f1ff !important;
  color: #d0b9fe !important;
}

.btnDisabledAlt {
  background-color: #decdfe !important;
}

.loader {
  align-self: center;
  border: 4px solid transparent;
  border-top: 4px solid white;
  border-right: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
.frequencyContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: MetropolisSemiBold;
  font-size: 14px;
  border-radius: 16px;
  padding: 4px 8px;
  background-color: #EBEBF53D;
  color: #EEF0F1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

