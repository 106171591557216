body {
  margin: 0;
  font-family: MetropolisRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

@font-face {
  font-family: "MetropolisRegular";
  src: local("MetropolisRegular"),
    url("./fonts/Metropolis-Regular.otf") format("truetype");
}

@font-face {
  font-family: "MetropolisThin";
  src: local("MetropolisThin"),
    url("./fonts/Metropolis-Thin.otf") format("truetype");
}

@font-face {
  font-family: "MetropolisMedium";
  src: local("MetropolisMedium"),
    url("./fonts/Metropolis-Medium.otf") format("truetype");
}

@font-face {
  font-family: "MetropolisSemiBold";
  src: local("MetropolisSemiBold"),
    url("./fonts/Metropolis-SemiBold.otf") format("truetype");
}

@font-face {
  font-family: "MetropolisBold";
  src: local("MetropolisBold"),
    url("./fonts/Metropolis-Bold.otf") format("truetype");
}

@font-face {
  font-family: "MetropolisExtraBold";
  src: local("MetropolisExtraBold"),
    url("./fonts/Metropolis-ExtraBold.otf") format("truetype");
}