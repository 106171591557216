.ToolTipParentContainer {
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000;
    padding: 16px;
    white-space: nowrap;
    word-wrap: break-word;
    border-radius: 8px;
    z-index: 1;
    overflow: visible;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9000;
    font-family: 'MetropolisMedium';
    font-size: 16px;
    line-height: 24px;
  }
  .ToolTipTriangle {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent; /* White background for the triangle */
  }
  