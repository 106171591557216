.skeletonLoader{
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0%{
        opacity: 0%;
    }

    100%{
        opacity: 90%;
    }
}