
.wrapperDiv {
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoImg {
    height: 48px;
    width: 128.81px;
    margin-bottom: 80px;
}

.profileImg {
    height: 120px; width: 120px; object-fit: cover; margin-bottom: 40px;
}

.callEndedTitle {
    margin-bottom: 16px; font-family: MetropolisSemiBold; font-size: 32px
}

.callEndedSubtitle {
    margin-bottom: 60px; font-family: MetropolisMedium; font-size: 20px
}

.button {
    margin: 0px;
    padding: 0px;
    // border: none;
    border-radius: 12px;
    cursor: pointer;
    background-color: #efefef;
}

.button :hover {
    border-radius: 12px;
    background-color: #dadada;
}

.buttonText {
    padding: 12px;
    font-family: MetropolisMedium;
    font-size: 24px
}

.buttonText :hover {
    padding: 12px;
    font-family: MetropolisMedium;
    font-size: 24px
}
.timeParagraph {
    color: var(--neutral-gray-400808-d-9-c, #808D9C);
    text-align: center;
    font-family: Metropolis;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}