.muteIcon {
  width: 100%;
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid
    var(--dark-mode-text-quaternary-ebebf-5, rgba(235, 235, 245, 0.24));
  background: #f94d59;
  position: absolute;
}
