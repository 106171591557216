.SelectBox{
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
.HeaderTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-family: MetropolisSemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    align-items: center;
  }
.HeaderSubtitle {
    font-family: "MetropolisMedium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #7A7A7A;
  }
 .formSection {
    margin-top: 24px;
    width: 100%;
    height: 308px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
  }
  .InputRow {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .InputLabelBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
  }
  .InputBoxDiv {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #DADEE1;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .InputFont {
    margin: 0;
    padding: 0;
    font-family: "MetropolisMedium";
    color: #7A7A7A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  
  .Input {
    flex: 1 0 0;
    border: none;
    outline: none;
    color: #7A7A7A;
    font-family: "MetropolisMedium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  
  
  
  