.popupBox {
    position: absolute;
    background: #00000050;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    width: 100vw;
    height: 100vh;
}

.box {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.image {
    position: relative;
    width: 80px;
    height: 80px;
    aspect-ratio: 1;

    border-radius: 50%;
    background-color: #5FC88E;
}

.tick {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popupText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}