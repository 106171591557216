.lobbyParentDiv {
    display: flex;
    flex-direction: column;
    flex: 25%;
    border-radius: 12px;
    background: var(--Dark-Mode-Primary-Light, #FFF);
    box-shadow: 0px 16px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    background-color: #FFFFFF;
    justify-content: space-between;
    position: relative;
    opacity: 0; 
    overflow: hidden;
    transform: translateX(100%);
    visibility: hidden;
    transform-origin: right; 
    height: calc(100% - 24px);
  }
  .lobbyParentDivShow{
    padding: 16px;
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 24px;
  }
  .lobbyParentDivHide{
    opacity: 0;
    max-width: 0;
    transform: translateX(1);
    visibility: hidden;
    // transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .lobbyHeading {
    height: 32px;
    width: 100%;
    border-bottom: 1px solid #DADEE1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    p {
      color: #808D9C;
      font-family: MetropolisSemiBold;
      font-size: 14px;
    }
  }
  .lobbyCircle {
    display: flex;
    width: 36px;
    font-family: MetropolisSemiBold;
    height: 36px;
    font-size: 16px;
    font-weight: 900;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10.667px;
    color: white;
    border-radius: 116.364px;
    background: var(--blue-b-4005-d-9-eee, #5D9EEE);
  }
  
  
  