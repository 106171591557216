.mainContainer {
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.localVideoContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #2c2c2e;
  row-gap: 12px;
  margin: 24px;
  align-items: center;
}

.localDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-family: 'MetropolisRegular';
}

.localDetailsContainer h2 {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
}

.localDetailsContainer h5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
}

.namePlate {
  bottom: 7%;
  left: 2%;
  position: absolute;
  color: #ffffff;
  font-family: MetropolisMedium;
  background-color: #2c2c2e;
  text-align: center;
  padding: 4px;
  width: 45px;
  height: 32px;
  border-radius: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderImage {
  animation: spin 1s linear infinite;
}
.InputLabelBoxContainer {
  display: flex;
  text-align: center;
  align-items: center;
 
  margin-top: 16px;
  gap: 15px;
  
}
.InputBoxDiv {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #DADEE1;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 176px;
}
.InputFont {
  margin: 0;
  padding: 0;
  font-family: "MetropolisMedium";
  color: #7A7A7A;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.Input {
  flex: 1 0 0;
  border: none;
  outline: none;
  color: #7A7A7A;
  font-family: "MetropolisMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}